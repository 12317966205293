var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      staticClass: "pl-0 pr-2",
      attrs: { color: "white", depressed: "", small: "" },
      on: { click: _vm.click }
    },
    [
      _c("v-icon", { class: _vm.options.class }, [
        _vm._v(_vm._s(_vm.options.icon))
      ]),
      _c("span", {
        class: _vm.options.titleClass,
        domProps: {
          innerHTML: _vm._s(_vm.$te(_vm.label) ? _vm.$t(_vm.label) : _vm.label)
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }