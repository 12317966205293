var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass:
        "payment-type payment-type-redirect payment-type-monetaweb-paypal"
    },
    [
      _vm.paymentType.title
        ? _c("div", {
            staticClass: "payment-type-title",
            domProps: { innerHTML: _vm._s(_vm.paymentType.title) }
          })
        : _vm._e(),
      _c("div", {
        staticClass: "payment-type-description",
        domProps: { innerHTML: _vm._s(_vm.paymentType.descr) }
      }),
      _c("PrebilledAmount", {
        attrs: {
          mode: _vm.mode,
          paymentTypeId: _vm.paymentType.paymentTypeId,
          "order-id": _vm.orderId
        }
      }),
      _c("ResponseMessage", { attrs: { response: _vm.response } }),
      _c(
        "v-row",
        { staticClass: "d-flex justify-end" },
        [
          _vm.mode != "order" && !_vm.options.hidePrevButton
            ? _c(
                "v-col",
                {
                  staticClass: "d-flex align-center",
                  attrs: { cols: "12", md: "6" }
                },
                [
                  !_vm.$vuetify.breakpoint.xs &&
                  _vm.options.prevButton &&
                  _vm.options.prevButton.type === "link-button"
                    ? _c("LinkButton", {
                        class: _vm.options.prevButton.class,
                        attrs: {
                          options: _vm.options.prevButton.options,
                          label: _vm.options.prevButton.label
                        },
                        on: { click: _vm.goBack }
                      })
                    : _c(
                        "v-btn",
                        {
                          attrs: {
                            color: _vm.options.back.color,
                            outlined: _vm.options.back.outline,
                            depressed: "",
                            disabled: _vm.loading,
                            large: ""
                          },
                          on: { click: _vm.goBack }
                        },
                        [_vm._v(" " + _vm._s(_vm.options.back.label) + " ")]
                      )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            {
              staticClass: "d-flex justify-end",
              attrs: { cols: "12", md: "6" }
            },
            [
              _vm.options.confirm.show
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mt-3 mt-sm-0 ml-2 gateway-btn",
                      attrs: {
                        color: _vm.options.confirm.color,
                        outlined: _vm.options.confirm.outline,
                        depressed: "",
                        large: ""
                      },
                      on: { click: _vm.buy }
                    },
                    [_vm._v(" " + _vm._s(_vm.options.confirm.label) + " ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm.$te(`paymentTypeList.${_vm.paymentType.gatewayClient}.caption`)
        ? _c("i18n", {
            staticClass: "caption",
            attrs: {
              path: `paymentTypeList.${_vm.paymentType.gatewayClient}.caption`,
              tag: "div"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }